import {
    CommonModule,
    HashLocationStrategy,
    LocationStrategy,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire/compat';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { AppRoutingModule } from './app-routing.module';
import { BearerTokenInterceptor } from './services/bearer-token.interceptor';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { FormsModule } from '@angular/forms';
import { IconService } from './demo/service/icon.service';
import { InputTextModule } from 'primeng/inputtext';
import { NgModule } from '@angular/core';
import { NodeService } from './demo/service/node.service';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { PhotoService } from './demo/service/photo.service';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [AppComponent, NotfoundComponent],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        ButtonModule,
        FormsModule,
        InputTextModule,
        AngularFireModule.initializeApp(environment.firebase),
        ToastModule,
        ConfirmDialogModule,
        SidebarModule,
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BearerTokenInterceptor,
            multi: true,
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
