import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private fireauth: AngularFireAuth, private router: Router) {}

    canActivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.fireauth.authState.pipe(
            map((user) => {
                if (user) {
                    return true; // User is authenticated, allow access
                } else {
                    return this.router.createUrlTree(['/login']); // User is not authenticated, redirect to login
                }
            })
        );
    }
}
