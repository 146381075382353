import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './services/auth.guard';
import { BatchcodehistoryModule } from './screens/batchcodehistory/batchcodehistory.module';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    redirectTo: '/login',
                    pathMatch: 'full',
                },
                {
                    path: 'login',
                    loadChildren: () =>
                        import('./login/login.module').then(
                            (m) => m.LoginModule
                        ),
                },
                {
                    path: '',
                    component: AppLayoutComponent,

                    children: [
                        {
                            path: 'dashboard',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './demo/components/dashboard/dashboard.module'
                                ).then((m) => m.DashboardModule),
                        },
                        {
                            path: 'states',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import('./screens/states/states.module').then(
                                    (m) => m.StatesModule
                                ),
                        },
                        {
                            path: 'cities',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import('./screens/cities/cities.module').then(
                                    (m) => m.CitiesModule
                                ),
                        },
                        {
                            path: 'products',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './screens/products/products.module'
                                ).then((m) => m.ProductsModule),
                        },
                        {
                            path: 'useres',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import('./screens/useres/useres.module').then(
                                    (m) => m.UseresModule
                                ),
                        },
                        {
                            path: 'userredeem',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './screens/userredeem/userredeem.module'
                                ).then((m) => m.UserredeemModule),
                        },
                        {
                            path: 'usertransection',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './screens/usertransection/usertransection.module'
                                ).then((m) => m.UsertransectionModule),
                        },
                        {
                            path: 'rewarditem',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './screens/rewarditem/rewarditem.module'
                                ).then((m) => m.RewarditemModule),
                        },
                        {
                            path: 'batchcode',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './screens/batchcode/batchcode.module'
                                ).then((m) => m.BatchcodeModule),
                        },
                        {
                            path: 'batchcodehistory',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './screens/batchcodehistory/batchcodehistory.module'
                                ).then((m) => m.BatchcodehistoryModule),
                        },
                        {
                            path: 'storeinfo',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './screens/storeinfo/storeinfo.module'
                                ).then((m) => m.StoreinfoModule),
                        },
                        {
                            path: 'userprofilepage',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './screens/userprofilepage/userprofilepage.module'
                                ).then((m) => m.UserprofilepageModule),
                        },

                        {
                            path: 'uikit',
                            loadChildren: () =>
                                import(
                                    './demo/components/uikit/uikit.module'
                                ).then((m) => m.UIkitModule),
                        },
                        {
                            path: 'utilities',
                            loadChildren: () =>
                                import(
                                    './demo/components/utilities/utilities.module'
                                ).then((m) => m.UtilitiesModule),
                        },
                        {
                            path: 'documentation',
                            loadChildren: () =>
                                import(
                                    './demo/components/documentation/documentation.module'
                                ).then((m) => m.DocumentationModule),
                        },
                        {
                            path: 'blocks',
                            loadChildren: () =>
                                import(
                                    './demo/components/primeblocks/primeblocks.module'
                                ).then((m) => m.PrimeBlocksModule),
                        },
                        {
                            path: 'pages',
                            loadChildren: () =>
                                import(
                                    './demo/components/pages/pages.module'
                                ).then((m) => m.PagesModule),
                        },
                    ],
                },

                {
                    path: 'auth',
                    canActivate: [AuthGuard],
                    loadChildren: () =>
                        import('./demo/components/auth/auth.module').then(
                            (m) => m.AuthModule
                        ),
                },
                {
                    path: 'landing',
                    loadChildren: () =>
                        import('./demo/components/landing/landing.module').then(
                            (m) => m.LandingModule
                        ),
                },
                { path: 'notfound', component: NotfoundComponent },
                { path: '**', redirectTo: '/notfound' },
            ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
