<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="/dashboard">
        <img src="assets/Pramukh.png" alt="logo">
        <span>PRAMUKH</span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div  #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <i *ngIf="email" class="pi pi-user" style="font-size: 1.5em;padding-left:100px;padding-top:10px;color: #010507;">
            Hi, {{email}} </i>
        <button class="p-link layout-topbar-button" [routerLink]="'/login'">
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
        </button>
    </div>
</div>
