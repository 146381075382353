import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
    constructor(private angularFireAuth: AngularFireAuth) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Only add the bearer token to requests to the backend
        if (req.url.startsWith(environment.base_url)) {
            return from(this.addBearerToken(req, next));
        }
        return next.handle(req);
    }

    private async addBearerToken(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Promise<HttpEvent<any>> {
        const firebaseUser = await this.angularFireAuth.currentUser;
        const token = await firebaseUser?.getIdToken();
        if (token) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }
        return lastValueFrom(next.handle(req));
    }
}
